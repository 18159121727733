<template>
  <div class="warp">
    <van-search
        v-model="keyWord"
        show-action
        placeholder="请输入货架编号"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <div>
      <div v-for="item in dataList" :key="item.id" class="order-info">
        <div class="name">{{ item.address }}</div>
        <div class="time">下单时间： {{ item.createTime }}</div>
        <div class="infos">
          <span>{{ item.payType == 1 ? '微信支付' : '余额支付' }}</span>
          <span>{{ item.idx }}格口号</span>
          <span>{{ siteOrderStatus[item.status] }}</span>
        </div>
        <div class="name">网点：{{ item.netName }}</div>
        <van-card
            v-for="goodsInfo in item.orderItems" :key="goodsInfo.orderItemId"
            style="background: #f8f7f7"
            :num="goodsInfo.prodCount"
            :price="goodsInfo.price"
            :title="goodsInfo.prodName"
            :thumb="goodsInfo.pic"
        />
        <div style="display: flex;justify-content: right;" v-if="refundStatus.indexOf(item.status) > -1">
          <van-button class="van-btn" type="danger" @click="refund(item.orderNumber)">退款</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderList, orderRefund } from '../../assets/api/order.js'
import { Toast, Dialog } from "vant";
import { siteOrderStatus } from "../../util/orderStatus.js";

export default {
  data() {
    return {
      keyWord: '',
      dataList: [],
      siteOrderStatus: siteOrderStatus,
      // refundStatus: [3,4,5,6,10]
      refundStatus: [3,4,5,10]
    }
  },
  mounted() {

  },
  methods: {
    onSearch() {
      if (!this.keyWord) {
        Toast('请输入货架名称！');
        return
      }
      const params = {
        vmcName: this.keyWord,
        orderySource: 2,
        current: 1,
        size: 1000,
      }
      orderList(params).then(res => {
        this.dataList = res.data.data.records
      })
    },
    refund(orderNumber) {
      Dialog.confirm({
        title: '退款',
        message:'是否确认退款到支付账户中?',
      })
          .then(() => {
            const params = {
              orderNumber: orderNumber
            }
            orderRefund(params).then((res) => {
              if (res.data.code == 200) {
                Toast('退款成功');
                this.onSearch()
              }
            })
          })
    }
  }
}
</script>

<style scoped lang="less">
::v-deep(.van-card__thumb) {
  width: 60px;
  height: 60px;
}
::v-deep(.van-card__content) {
  height: 60px;
  min-height: 60px;
}
.warp {
  padding: 15px 10px;

  ::v-deep(.van-tabs__nav) {
    margin: 0!important;
  }

  .order-info {
    width: calc(100% - 20px);
    color: #666;
    font-size: 12px;
    border: 1px solid #aaaaaa;
    border-radius: 8px;
    margin-top: 15px;
    padding: 5px 10px;
    position: relative;
    background: #f7f8fabd;

    .name {
      font-size: 16px;
      line-height: 18px;
    }
    .time, .infos {
      margin: 4px 0;
      line-height: 18px;
    }
    .infos {
      display: flex;
      span {
        margin-right: 10px;
        border: 1px solid #999999;
        border-radius: 6px;
        padding: 0 6px;
      }
    }
    .van-btn {
      border: none;
      height: 24px;
      line-height: 24px;
      margin-top: 10px;
      margin-right: 20px;
      border-radius: 5px;
    }
  }
}
</style>
